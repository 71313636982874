import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import home1x from '../assets/menu-overlay-home.jpg';
import home2x from '../assets/menu-overlay-home.jpg';
import projects1x from '../assets/menu-overlay-projects.jpg';
import projects2x from '../assets/menu-overlay-projects.jpg';
import profile1x from '../assets/menu-overlay-profile.jpg';
import profile2x from '../assets/menu-overlay-profile.jpg';
import contact1x from '../assets/menu-overlay-contact.jpg';
import contact2x from '../assets/menu-overlay-contact.jpg';

const Menu = ({ linkClick, close, isOpen }) => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const isTabletOrMobile = window.matchMedia('(max-width: 1024px)').matches;

    const refContainer = useRef(null);
    const refNav = useRef(null);
    const refItems = useRef(null);
    const [posX, setPosX] = useState(0);
    const [hoverId, setHoverId] = useState(null);
    const [prevHoverId, setPrevHoverId] = useState(1);

    const refTouchStartX = useRef(0);
    const refStartPosX = useRef(0);

    const handleMouseMove = (e) => {
        if (isTabletOrMobile) {
            return;
        }
        const containerWidth = refContainer.current.getBoundingClientRect().width;
        const mousePosX = e.clientX;
        const percent = mousePosX / containerWidth - 0.5;

        const navWidth = refNav.current.getBoundingClientRect().width;
        const itemsWidth = refItems.current.getBoundingClientRect().width;
        const diff = itemsWidth - navWidth;
        const translateX = -1 * diff * percent;
        setPosX(translateX);
    };

    const mouseEnter = (id) => () => {
        if (isTabletOrMobile) {
            return;
        }
        setPrevHoverId(id);
        setHoverId(id);
    };

    const mouseLeave = () => {
        if (isTabletOrMobile) {
            return;
        }
        setPrevHoverId(hoverId);
        setHoverId(null);
    };

    const handleTouchMove = (e) => {
        if (!isTabletOrMobile && isMobile) {
            return;
        }
        const navWidth = refNav.current.getBoundingClientRect().width;
        const itemsWidth = refItems.current.getBoundingClientRect().width;
        const movableSpace = (itemsWidth - navWidth) / 2;

        const moveDiff = e.touches[0].clientX - refTouchStartX.current;
        let newPosX = refStartPosX.current + moveDiff;
        if (newPosX < -movableSpace) {
            newPosX = -movableSpace;
        }
        if (newPosX > movableSpace) {
            newPosX = movableSpace;
        }
        setPosX(newPosX);
    };

    const touchStart = (id) => (e) => {
        if (!isTabletOrMobile && isMobile) {
            return;
        }
        refTouchStartX.current = e.touches[0].clientX;
        refStartPosX.current = posX;
        setPrevHoverId(id);
        setHoverId(id);
    };

    const touchEnd = () => {
        if (!isTabletOrMobile && isMobile) {
            return;
        }
        setPrevHoverId(hoverId);
        setHoverId(null);
    };

    return (
        <div
            className={cx('container-menu', { open: isOpen })}
            ref={refContainer}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
        >
            <div className="layout layout-padding-hor">
                <div className="page-menu">
                    <div className="menu-header">
                        <div className="menu-header-social">
                            <a
                                href="https://www.instagram.com/explore/tags/zalecontracting/"
                                className="menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                instagram
                            </a>
                            <br />
                            <a
                                href="https://www.facebook.com/ZaleContractingInc/"
                                className="menu-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                facebook
                            </a>
                        </div>
                        <div className="menu-header-close" onClick={close} />
                    </div>
                    <div className="menu-nav" ref={refNav}>
                        <div className={cx('menu-image', { hover: hoverId === 1, forward: prevHoverId === 1 })}>
                            <img src={home1x} srcSet={`${home1x} 1x, ${home2x} 2x`} alt="Home" />
                        </div>
                        <div className={cx('menu-image', { hover: hoverId === 2, forward: prevHoverId === 2 })}>
                            <img src={projects1x} srcSet={`${projects1x} 1x, ${projects2x} 2x`} alt="Projects" />
                        </div>
                        <div className={cx('menu-image', { hover: hoverId === 3, forward: prevHoverId === 3 })}>
                            <img src={profile1x} srcSet={`${profile1x} 1x, ${profile2x} 2x`} alt="Profile" />
                        </div>
                        <div className={cx('menu-image', { hover: hoverId === 4, forward: prevHoverId === 4 })}>
                            <img src={contact1x} srcSet={`${contact1x} 1x, ${contact2x} 2x`} alt="Contact" />
                        </div>
                        <nav
                            className="menu-items"
                            ref={refItems}
                            style={{ transform: `translate(calc(-50% + ${posX}px), -50%)` }}
                        >
                            <Link
                                to="/"
                                className="menu-item"
                                onClick={linkClick('Home', '/')}
                                onMouseEnter={mouseEnter(1)}
                                onMouseLeave={mouseLeave}
                                onTouchStart={touchStart(1)}
                                onTouchEnd={touchEnd}
                            >
                                Home
                            </Link>
                            <Link
                                to="/projects/"
                                className="menu-item"
                                onClick={linkClick('Projects', '/projects/')}
                                onMouseEnter={mouseEnter(2)}
                                onMouseLeave={mouseLeave}
                                onTouchStart={touchStart(2)}
                                onTouchEnd={touchEnd}
                            >
                                Projects
                            </Link>
                            <Link
                                to="/profile/"
                                className="menu-item"
                                onClick={linkClick('Profile', '/profile/')}
                                onMouseEnter={mouseEnter(3)}
                                onMouseLeave={mouseLeave}
                                onTouchStart={touchStart(3)}
                                onTouchEnd={touchEnd}
                            >
                                Profile
                            </Link>
                            <Link
                                to="/contact/"
                                className="menu-item"
                                onClick={linkClick('Contact', '/contact/')}
                                onMouseEnter={mouseEnter(4)}
                                onMouseLeave={mouseLeave}
                                onTouchStart={touchStart(4)}
                                onTouchEnd={touchEnd}
                            >
                                Contact
                            </Link>
                        </nav>
                        <div className="menu-drag">
                            <img src={require('../assets/drag.svg')} alt="" />
                        </div>
                    </div>
                    <div className="menu-footer">
                        <address className="menu-footer-address">
                            <a href="https://goo.gl/maps/h6r7XqeJ5AA8Zb9U9" target="_blank" rel="noopener noreferrer">
                                <span className="menu-link">1740 Decatur Street</span>
                                <br />
                                <span className="menu-link">Ridgewood, New York 11385</span>
                            </a>
                        </address>
                        <div className="menu-footer-hours">
                            <span>Office Hours:</span>
                            <br />
                            <span>M-F 8:00 AM - 4:00 PM</span>
                        </div>
                        <div className="menu-footer-contact">
                            <a className="menu-link" href="tel:718 366 1092">
                                (718) 366-1092
                            </a>
                            <br />
                            <a className="menu-link" href="mailto:info@zaleinc.net">
                                info@zaleinc.net
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;
