import React, { Fragment } from 'react';

import useHomeIndex from '../hooks/useHomeIndex';

import HomeTriangle from './HomeTriangle';
import HomeIntro from './HomeIntro';
import HomeContracting from './HomeContracting';
import HomeTour from './HomeTour';
import HomeWoodworking from './HomeWoodworking';

const Home = ({ linkClick, translateY }) => {
    const index = useHomeIndex(translateY);

    return (
        <Fragment>
            <HomeTriangle translateY={translateY} index={index} />
            <HomeIntro linkClick={linkClick} />
            <HomeContracting linkClick={linkClick} />
            <HomeTour linkClick={linkClick} />
            <HomeWoodworking linkClick={linkClick} />
        </Fragment>
    );
};

export default Home;
