import React, { Fragment } from 'react';
import cx from 'classnames';

const ProjectSingleImages = ({ project }) => (
    <div className="container-single-images">
        <div className="page-single-images">
            {project.gallery.map((item, index) => (
                <Fragment key={index}>
                    {item.type === 'single' && (
                        <div className="layout layout-padding-hor">
                            <div className="single-images-row">
                                <div className="single-images-single">
                                    <img
                                        src={item.image_1}
                                        srcSet={`${item.image_1} 1x, ${item.image_1_2x} 2x`}
                                        alt={`${project.title} / ${project.city}`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {item.type === 'double' && (
                        <div className="layout layout-padding-hor">
                            <div className="single-images-row single-images-row--flex single-images-row--double">
                                <div
                                    className={cx('single-images-half', {
                                        'single-images-half--small': item.image_1_small === '1',
                                    })}
                                >
                                    <img
                                        src={item.image_1}
                                        srcSet={`${item.image_1} 1x, ${item.image_1_2x} 2x`}
                                        alt={`${project.title} / ${project.city}`}
                                    />
                                </div>
                                <div
                                    className={cx('single-images-half', {
                                        'single-images-half--small': item.image_2_small === '1',
                                    })}
                                >
                                    <img
                                        src={item.image_2}
                                        srcSet={`${item.image_2} 1x, ${item.image_2_2x} 2x`}
                                        alt={`${project.title} / ${project.city}`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {item.type === 'image-text' && (
                        <div className="layout layout-padding-hor">
                            <div className="single-images-row single-images-row--flex single-images-row--bg">
                                <div className="single-images-half">
                                    <img
                                        src={item.image_1}
                                        srcSet={`${item.image_1} 1x, ${item.image_1_2x} 2x`}
                                        alt={`${project.title} / ${project.city}`}
                                    />
                                </div>
                                <div className="single-images-half">
                                    <h2 className="single-images-text">{item.text}</h2>
                                </div>
                            </div>
                        </div>
                    )}
                    {item.type === 'text-image' && (
                        <div className="layout layout-padding-hor">
                            <div className="single-images-row single-images-row--flex single-images-row--bg">
                                <div className="single-images-half">
                                    <img
                                        src={item.image_1}
                                        srcSet={`${item.image_1} 1x, ${item.image_1_2x} 2x`}
                                        alt={`${project.title} / ${project.city}`}
                                    />
                                </div>
                                <div className="single-images-half">
                                    <h2 className="single-images-text">{item.text}</h2>
                                </div>
                            </div>
                        </div>
                    )}
                    {item.type === 'full' && (
                        <div className="single-images-row">
                            <div className="single-images-full">
                                <img
                                    src={item.image_1}
                                    srcSet={`${item.image_1} 1x, ${item.image_1_2x} 2x`}
                                    alt={`${project.title} / ${project.city}`}
                                />
                            </div>
                        </div>
                    )}
                </Fragment>
            ))}
        </div>
    </div>
);

export default ProjectSingleImages;
