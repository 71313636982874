import React from 'react';

import { Header, Menu } from 'features/header';
import { Cover } from 'features/cover';

import SmoothScroll from './SmoothScroll';
import useResizeVh from '../hooks/useResizeVh';
import useContent from '../hooks/useContent';

const Content = () => {
    useResizeVh();
    const [menuIsOpen, menuOpen, menuClose, coverState, coverProgress, linkClick, homeIndex] = useContent();

    return (
        <div className="layout layout-bg">
            <Cover state={coverState} progress={coverProgress} />
            <Header menuOpen={menuOpen} linkClick={linkClick} homeIndex={homeIndex} />
            <Menu linkClick={linkClick} close={menuClose} isOpen={menuIsOpen} homeIndex={homeIndex} />
            <SmoothScroll linkClick={linkClick} />
        </div>
    );
};

export default Content;
