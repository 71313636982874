import React, { Fragment } from 'react';

import ProfileIntro from './ProfileIntro';
import ProfileAbout from './ProfileAbout';
import ProfileFounder from './ProfileFounder';
import ProfilePress from './ProfilePress';

const Profile = ({ translateY }) => (
    <Fragment>
        <ProfileIntro translateY={translateY} />
        <ProfileAbout />
        <ProfileFounder />
        <ProfilePress />
    </Fragment>
);

export default Profile;
