import { useEffect } from 'react';

const useResizeVh = () => {
    const touchsupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    const handleWindowResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        if (!touchsupport) {
            // browser doesn't support touch
            document.documentElement.className += ' non-touch';
        }

        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        return () => {
            window.removeEventListener('resize', handleWindowResize, false);
        };
    }, [touchsupport]);

    return null;
};

export default useResizeVh;
