import React, { Fragment } from 'react';

import ProjectsList from './ProjectsList';
import ProjectsInfo from './ProjectsInfo';

const Projects = ({ linkClick }) => (
    <Fragment>
        <ProjectsList linkClick={linkClick} />
        <ProjectsInfo />
    </Fragment>
);

export default Projects;
