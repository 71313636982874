import React, { Fragment, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

const modalRoot = document.getElementById('modal-root');

const SinglePopup = ({ close, isOpen, code }) => {
    return ReactDOM.createPortal(
        <div className={cx('single-popup', { 'single-popup--open': isOpen })}>
            <iframe
                src={`https://my.matterport.com/show/?m=${code}&brand=0`}
                frameBorder="0"
                allowFullScreen
                title="Virtual Tour"
            ></iframe>
            <div className="single-popup-close" onClick={close}></div>
        </div>,
        modalRoot,
    );
};

const SingleZoom = ({ project }) => {
    const refImageContainer = useRef(null);
    const [popupIsOpen, setIsPopupOpen] = useState(false);
    const popupOpen = () => setIsPopupOpen(true);
    const popupClose = () => setIsPopupOpen(false);

    let isScrolled = false;
    if (refImageContainer.current) {
        const posTop = refImageContainer.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        isScrolled = posTop < windowHeight / 2;
    }

    return (
        <Fragment>
            <div className={cx('single-zoom', { 'single-zoom--scrolled': isScrolled })} ref={refImageContainer}>
                <img
                    src={project.zoom_image}
                    srcSet={`${project.zoom_image} 1x, ${project.zoom_image_2x} 2x`}
                    alt={`${project.title} / ${project.city}`}
                />
                {project.code !== '' && (
                    <div className="single-zoom-button" onClick={popupOpen}>
                        <span>Start</span>
                        <span>Virtual</span>
                        <span>Tour</span>
                    </div>
                )}
            </div>
            {project.code !== '' && <SinglePopup close={popupClose} isOpen={popupIsOpen} code={project.code} />}
        </Fragment>
    );
};

export default SingleZoom;
