const convertItemsToRows = (items) => {
    const rows = [];
    let rowCount = 0;
    let row = [];
    for (let i = 0; i < items.length; i++) {
        const currentItem = items[i];

        rowCount += 1;
        if (currentItem.list_type === 'single') {
            row.push(currentItem);
            rows.push({
                type: 'single',
                items: row,
            });
            row = [];
            rowCount = 0;
        } else if (currentItem.list_type === 'horizontal' || currentItem.list_type === 'vertical') {
            row.push(currentItem);

            if (rowCount >= 2) {
                rows.push({
                    type: 'double',
                    items: row,
                });
                row = [];
                rowCount = 0;
            }
        }
    }

    if (row.length > 0) {
        rows.push({
            type: 'double',
            items: row,
        });
    }

    return rows;
};

export default convertItemsToRows;
