import { useState, useRef } from 'react';

const useHover = () => {
    const refImage = useRef(null);
    const [hover, setHover] = useState(false);
    const [hoverPos, setHoverPos] = useState({ x: 0, y: 0 });

    const mouseEnter = () => setHover(true);
    const mouseLeave = () => setHover(false);
    const mouseMove = (e) => {
        if (!refImage.current) {
            return;
        }
        const rect = refImage.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setHoverPos({ x, y });
    };

    return [refImage, hover, hoverPos, mouseEnter, mouseLeave, mouseMove];
};

export default useHover;
