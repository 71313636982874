import React from 'react';

const ProjectSingleIntro = ({ project }) => (
    <div className="container-single-intro">
        <div className="layout layout-padding-hor">
            <div className="page-single-intro">
                <div className="single-intro-header">
                    <h1 className="single-intro-title">
                        {project.title}
                        <br />
                        <span className="gold">{project.city}</span>
                    </h1>
                </div>
                <div className="single-intro-details">
                    {project.details.map(({ label, value }, index) => (
                        <dl className="single-intro-item" key={index}>
                            <dt className="single-intro-item-label">{label}</dt>
                            <dd className="single-intro-item-value">{value}</dd>
                        </dl>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default ProjectSingleIntro;
