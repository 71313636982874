import React, { Fragment, useRef } from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import imgZoom from '../assets/intro/intro-zoom.jpg';
import imgZoom2x from '../assets/intro/intro-zoom@2x.jpg';

const ProfileIntro = ({ translateY }) => {
    const refImageContainer = useRef(null);

    let isScrolled = false;
    if (refImageContainer.current) {
        const posTop = refImageContainer.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        isScrolled = posTop < windowHeight / 2;
    }

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    return (
        <Fragment>
            <div className={cx('container-profile-intro', { 'in-view': inView })} ref={ref}>
                <div className="layout layout-padding-hor">
                    <div className="page-profile-intro">
                        <h1 className="profile-intro-title element-title-in-view">
                            <span>Zale</span> <span>Contracting</span> <span>is a</span>{' '}
                            <span className="gold">family</span> <span className="gold">owned</span>{' '}
                            <span className="gold">and</span> <span className="gold">operated</span>{' '}
                            <span className="gold">business</span> <span>constructing</span> <span>high end</span>{' '}
                            <span>renovations</span> <span>for over</span> <span>the past</span> <span>30 years.</span>
                        </h1>
                        {!isMobile && (
                            <p className="profile-intro-text element-in-view element-in-view-11">
                                Zale is a home to nearly 80 employees that have been working with us for years and share
                                our dedication to process and outcomes. Their collaborative energy and collective
                                expertise, in conjunction with our high demands, elevate our company to the unreachable
                                heights.
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={cx('profile-intro-image', { 'profile-intro-image--scrolled': isScrolled })}
                ref={refImageContainer}
            >
                <img src={imgZoom} srcSet={`${imgZoom} 1x, ${imgZoom2x} 2x`} alt="Zale Contracting" />
            </div>
            {isMobile && (
                <div className="layout layout-padding-hor">
                    <div className="page-profile-intro-after">
                        <p className="profile-intro-text">
                            Zale is a home to nearly 80 employees that have been working with us for years and share our
                            dedication to process and outcomes. Their collaborative energy and collective expertise, in
                            conjunction with our high demands, elevate our company to the unreachable heights.
                        </p>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ProfileIntro;
