import React, { Fragment } from 'react';

import ContactForm from './ContactForm';
import ContactHeader from './ContactHeader';

const Contact = () => (
    <Fragment>
        <ContactHeader />
        <ContactForm />
    </Fragment>
);

export default Contact;
