import React from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

const ProfileAbout = () => {
    const [ref1, inView1] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    const [ref2, inView2] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    return (
        <div className="container-profile-about">
            <div className="container-profile-about-bg">
                <div className="layout layout-bg"></div>
            </div>
            <div className="layout layout-padding-hor">
                <div className="page-profile-about">
                    <div className={cx('profile-about-col', 'profile-about-col-1', { 'in-view': inView1 })} ref={ref1}>
                        <div className="profile-about-block">
                            <h2 className="profile-about-title element-title-in-view">
                                <span>We</span> <span>start</span> <span>our</span> <span>process</span>{' '}
                                <span>with</span> <span>a preconstruction</span> <span>consultation</span>
                            </h2>
                            <p className="profile-about-text element-in-view element-in-view-8">
                                To help us better understand our client’s vision and expectations, establish timelines,
                                budget, materials and all associated costs. With goals and plan in place, we carefully
                                go through the process of selecting the best of the best from the required trades to
                                assure efficiency and getting the job done right.
                            </p>
                        </div>
                        <div className="profile-about-image element-in-view element-in-view-3">
                            <img src={require('../assets/profile-about-1.jpg')} alt="" />
                        </div>
                    </div>
                    <div className={cx('profile-about-col', 'profile-about-col-2', { 'in-view': inView2 })} ref={ref2}>
                        <div className="profile-about-image element-in-view element-in-view-1">
                            <img src={require('../assets/profile-about-2.jpg')} alt="" />
                        </div>
                        <div className="profile-about-block">
                            <h3 className="profile-about-title element-title-in-view">
                                <span>Zale’s</span> <span>in-house</span> <span>drafting</span> <span>department</span>
                            </h3>
                            <p className="profile-about-text element-in-view element-in-view-5">
                                works side by side with our woodworking shop to deliver a wide variety of custom
                                millwork and finishes, including but not limited to trim, doors & windows, cabinetry,
                                architectural metal & glass. We are current on technical advances in new products,
                                construction techniques, latest technology and design trends.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileAbout;
