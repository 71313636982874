import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import useHover from '../hooks/useHover';
import useSlider from '../hooks/useSlider';

import img1 from '../assets/woodworking/home-wood-1.jpg';
import img1_2x from '../assets/woodworking/home-wood-1@2x.jpg';
import img2 from '../assets/woodworking/home-wood-2.jpg';
import img2_2x from '../assets/woodworking/home-wood-2@2x.jpg';

const slides = [
    {
        img: img1,
        img2x: img1_2x,
        alt: 'Architectural Woodworking',
    },
    {
        img: img2,
        img2x: img2_2x,
        alt: 'Architectural Woodworking',
    },
];

const HomeWoodworking = ({ linkClick }) => {
    const [refImage, hover, hoverPos, mouseEnter, mouseLeave, mouseMove] = useHover();
    const hoverStyle = {
        transform: `translate(${hoverPos.x}px, ${hoverPos.y}px)`,
    };

    const [slide, slidePrev] = useSlider(slides.length);
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div className={cx('container-home-woodworking', { 'in-view': inView })} ref={ref}>
            <div className="layout layout-padding-hor">
                <div className="page-home-woodworking">
                    <div className="home-woodworking-content">
                        <h2 className="home-woodworking-title element-title-in-view">
                            <span>Architectural</span>
                            <br /> <span>Woodworking</span>
                        </h2>
                        <p className="home-woodworking-text element-in-view element-in-view-3">
                            With its state of the art equipment and the finest European craftsmanship, Zale is capable
                            of handling even the most challenging projects delivering exceptional quality. Every detail
                            is well thought-out and can be custom fabricated to any need.
                        </p>
                        <Link
                            to={`/projects/`}
                            onClick={linkClick('Projects', `/projects/`)}
                            className="home-woodworking-link element-in-view element-in-view-4"
                        >
                            View Projects
                        </Link>
                    </div>
                    <div
                        ref={refImage}
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                        onMouseMove={mouseMove}
                        className="home-woodworking-image home-hover-container element-in-view element-in-view-4"
                    >
                        <div className="home-slider">
                            {slides.map(({ img, img2x, alt }, index) => (
                                <img
                                    className={cx({ active: index === slide, prev: index === slidePrev })}
                                    key={index}
                                    src={img}
                                    srcSet={`${img} 1x, ${img2x} 2x`}
                                    alt={alt}
                                />
                            ))}
                        </div>
                        <Link
                            to={`/projects/`}
                            onClick={linkClick('Projects', `/projects/`)}
                            className={cx('home-hover', { hovering: hover })}
                            style={hoverStyle}
                        >
                            <span>
                                View
                                <br /> Projects
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeWoodworking;
