import { useEffect, useRef } from 'react';

const getHeight = (className) => {
    const element = document.querySelector(className);
    if (element) {
        return element.getBoundingClientRect().height;
    }
    return 0;
};

const getHeightTour = () => {
    const elementBg = document.querySelector('.home-tour-bg');
    const elementImage = document.querySelector('.home-tour-image');
    if (elementBg && elementImage) {
        const styleBg = window.getComputedStyle(elementBg);
        const styleImage = window.getComputedStyle(elementImage);

        const bgMarginTop = parseInt(styleBg.marginTop);
        const bgHeight = elementBg.getBoundingClientRect().height;

        const imageMarginTop = parseInt(styleImage.marginTop);
        const imageMarginBottom = parseInt(styleImage.marginBottom);
        const imageHeight = elementImage.getBoundingClientRect().height;

        const height = bgMarginTop + bgHeight + imageMarginTop + imageMarginBottom + imageHeight;
        return height;
    }
    return 0;
};

const useHomeIndex = (translateY) => {
    const refElements = useRef([]);

    useEffect(() => {
        const updateSizes = () => {
            const items = [];
            let height = 0;
            let sum = 0;

            height = getHeight('.container-home-intro');
            if (height > 0) {
                sum += height;
                items.push(sum);
            }

            height = getHeight('.container-home-contracting');
            if (height > 0) {
                sum += height;
                items.push(sum);
            }

            height = getHeightTour();
            if (height > 0) {
                sum += height;
                items.push(sum);
            }

            height = getHeight('.container-home-woodworking');
            if (height > 0) {
                sum += height;
                items.push(sum);
            }

            height = getHeight('.container-footer');
            if (height > 0) {
                sum += height;
                items.push(sum);
            }

            if (items.length === 5) {
                refElements.current = items;
            }
        };

        updateSizes();
        setTimeout(updateSizes, 1000);
        window.addEventListener('resize', updateSizes);
        return () => {
            window.removeEventListener('resize', updateSizes);
        };
    }, []);

    const index = refElements.current.findIndex((sum) => sum > translateY + window.innerHeight * 0.3);

    return index;
};

export default useHomeIndex;
