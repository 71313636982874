import React, { useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { AppContext } from 'features/app';

import convertItemsToRows from '../utils/convertItemsToRows';

const Row = ({ rowItems, rowType, linkClick }) => (
    <div className={cx('projects-list-row', `projects-list-row--${rowType}`)}>
        {rowItems.map((item, index) => (
            <Link
                to={`/projects/${item.slug}/`}
                key={index}
                className={cx('projects-list-item', {
                    'projects-list-item--hor': item.list_type === 'horizontal',
                    'projects-list-item--ver': item.list_type === 'vertical',
                })}
                onClick={linkClick('Projects', `/projects/${item.slug}/`)}
            >
                <div className="projects-list-image">
                    <img
                        src={item.list_image}
                        srcSet={`${item.list_image} 1x, ${item.list_image_2x} 2x`}
                        alt={`${item.title} / ${item.city}`}
                    />
                    <div className="projects-list-cover"></div>
                </div>
                <div className="projects-list-text">
                    <div className="projects-list-line"></div>
                    <div className="projects-list-name">
                        {item.title} / {item.city}
                    </div>
                    <div className="projects-list-hover">View Project</div>
                </div>
            </Link>
        ))}
    </div>
);

const ProjectsList = ({ linkClick }) => {
    const projects = useContext(AppContext).projects;
    const rows = convertItemsToRows(projects);

    return (
        <div className="container-projects-list">
            <div className="layout layout-padding-hor">
                <div className="page-projects-list">
                    <div className="projects-list-header">
                        <h1 className="projects-list-title">
                            Our highly skilled{' '}
                            <span className="gold">Craftsmen, Architects, Designers, and Consultants</span> perfect the
                            art of design to develop their client’s personal and professional environments.
                        </h1>
                    </div>
                    <div className="projects-list-items">
                        {rows.map((row, index) => (
                            <Row rowItems={row.items} rowType={row.type} key={index} linkClick={linkClick} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsList;
