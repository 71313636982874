import React from 'react';
import cx from 'classnames';

const HomeTriangle = ({ translateY, index }) => {
    let posY = translateY;

    let scrollHeight = 0;
    if (document.querySelector('.smooth-scroll-scrollable')) {
        scrollHeight = document.querySelector('.smooth-scroll-scrollable').scrollHeight;
    }
    const windowHeight = window.innerHeight;
    let footerHeight = 0;
    if (document.querySelector('.container-footer')) {
        footerHeight = document.querySelector('.container-footer').getBoundingClientRect().height;
    }
    let woodworkingHeight = 0;
    if (document.querySelector('.container-home-woodworking')) {
        woodworkingHeight = document.querySelector('.container-home-woodworking').getBoundingClientRect().height;
    }

    const bottomHeight = footerHeight + woodworkingHeight;

    const scrollEnd = Math.abs(posY + windowHeight - scrollHeight) < 10;
    if (scrollEnd || posY > scrollHeight - bottomHeight) {
        posY = scrollHeight - bottomHeight;
    }

    return (
        <div
            className={cx('home-triangle', `home-triangle-${index < 0 ? 0 : index}`, {
                'home-triangle-end': scrollEnd,
            })}
            style={{ transform: `translate(calc(50vw + 1px), ${posY}px)` }}
        >
            <div className="home-triangle-inside"></div>
        </div>
    );
};

export default HomeTriangle;
