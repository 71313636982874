import React from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

const ProjectsInfo = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div className={cx('container-projects-info', { 'in-view': inView })} ref={ref}>
            <div className="layout layout-padding-hor layout-bg">
                <div className="page-projects-info">
                    <h1 className="projects-info-title element-title-in-view">
                        <span>Zale – </span>
                        <br />
                        <span>Selects</span> <span>only</span> <span>the</span> <span>highest</span>{' '}
                        <span>quality</span> <span>materials</span> <span>from</span> <span>the</span>{' '}
                        <span>country’s</span> <span>finest</span> <span>vendors.</span>
                    </h1>
                </div>
            </div>
        </div>
    );
};
export default ProjectsInfo;
