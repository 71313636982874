import { useState, useRef, useEffect } from 'react';

const useSlider = (count) => {
    const [slide, setSlide] = useState(0);
    const [slidePrev, setSlidePrev] = useState(0);
    const refTimer = useRef(null);

    useEffect(() => {
        const nextSlide = () => {
            setSlide((slideIndex) => {
                setSlidePrev(slideIndex);
                let nextIndex = slideIndex + 1;
                if (nextIndex > count - 1) {
                    nextIndex = 0;
                }
                return nextIndex;
            });
        };
        refTimer.current = setInterval(nextSlide, 6000);
        return () => clearInterval(refTimer.current);
    }, [count]);

    return [slide, slidePrev];
};

export default useSlider;
