import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

import AppContext from '../context/AppContext';

import Content from './Content';

const App = () => {
    const [data, setData] = useState({});
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const result = await axios('https://api.zaleinc.net/data');
            setData(result.data);
            setInitialized(true);
        }
        fetchData();
    }, []);

    if (!initialized) {
        return null;
    }

    return (
        <AppContext.Provider value={data}>
            <Router basename={process.env.PUBLIC_URL}>
                <Content />
            </Router>
        </AppContext.Provider>
    );
};

export default App;
