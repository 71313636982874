import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import cx from 'classnames';

import { useHomeIndex } from 'pages/home';

const Header = ({ menuOpen, linkClick }) => {
    const [translateY, setTranslateY] = useState(window.pageYOffset || document.documentElement.scrollTop);
    const isHome = useLocation().pathname === '/';
    let homeIndex = useHomeIndex(translateY);
    homeIndex = isHome ? homeIndex : -1;

    const [overFooter, setOverFooter] = useState(false);
    useEffect(() => {
        const updateScroll = () => {
            const isMobile = window.matchMedia('(max-width: 767px)').matches;
            const scrollHeight = document.body.scrollHeight;
            const scrollY = window.pageYOffset || document.documentElement.scrollTop;

            let footerHeight = 0;
            const footerElem = document.querySelector('.container-footer');
            if (footerElem) {
                footerHeight = footerElem.getBoundingClientRect().height;
            }

            const distanceFromEnd = scrollHeight - scrollY - footerHeight;
            const distanceChange = isMobile ? 40 : 72;
            setOverFooter(distanceFromEnd < distanceChange);

            setTranslateY(scrollY);
        };

        updateScroll();
        window.addEventListener('scroll', updateScroll);
        return () => {
            window.removeEventListener('scroll', updateScroll);
        };
    });

    return (
        <div className="container-header">
            <div className="layout layout-padding-hor">
                <div className="page-header">
                    <Link
                        to="/"
                        onClick={linkClick('Home', '/')}
                        className={cx('header-logo', { 'header-logo--footer': overFooter })}
                    >
                        <svg
                            width="140"
                            height="44"
                            viewBox="0 0 140 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M28.5025 0.000244141L0.00012207 43.8512H10.9634L39.4658 0.000244141H28.5025Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M55.2886 43.8509H64.0587V30.3579L55.2886 43.8509Z"
                                className={cx('header-logo-triangle', {
                                    'header-logo-triangle-home-2': homeIndex === 1,
                                    'header-logo-triangle-home-3': homeIndex === 2,
                                    'header-logo-triangle-home-4': homeIndex === 3,
                                })}
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M125.324 19.3953V32.8882L134.094 19.3953H125.324Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M82.2195 43.8509H90.99V0H82.2195V43.8509Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M67.9683 0.000244141V0.000630881L39.4659 43.8512H50.4289L67.9683 16.8671V43.8512H76.7384V0.000244141H67.9683Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M111.072 43.8509H119.843V0H111.072V43.8509Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M100.764 33.9424L94.4761 43.8507H105.591V33.9424V26.355L100.764 33.9424Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M134.825 33.9424L128.536 43.8507H139.651V33.9424V26.355L134.825 33.9424Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M128.536 0.000244141L134.825 9.90814L139.651 17.496V9.90814V0.000244141H128.536Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M29.1579 33.9424L22.8695 43.8507H33.9844V33.9424V26.355L29.1579 33.9424Z"
                                className="header-logo-text"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.48132 0.000244141V9.90814V17.496L10.3075 9.90814L16.5962 0.000244141H5.48132Z"
                                className="header-logo-text"
                            />
                        </svg>
                    </Link>
                    <div
                        onClick={menuOpen}
                        className={cx('header-trigger', {
                            'header-trigger-home-2': homeIndex === 1,
                            'header-trigger-home-3': homeIndex === 2,
                            'header-trigger-home-4': homeIndex === 3,
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
