import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useCover } from 'features/cover';
import { useMenu } from 'features/header';

const useContent = () => {
    const [menuIsOpen, menuOpen, menuClose] = useMenu();
    const [coverState, coverDispatch] = useCover();

    const history = useHistory();
    useEffect(() => {
        if (coverState.step === 1) {
            window.scrollTo(0, 0);
            menuClose();
        }
        if (coverState.step === 2) {
            history.push(coverState.url);
        }
    }, [coverState.step, coverState.url, history, menuClose]);

    const linkClick = (text, url) => (e) => {
        e.preventDefault();
        if (coverState.active) {
            return;
        }
        coverDispatch({ type: 'start', payload: { url, text } });
    };

    const coverProgress = () => coverDispatch({ type: 'progress' });

    return [menuIsOpen, menuOpen, menuClose, coverState, coverProgress, linkClick];
};

export default useContent;
