import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { AppContext } from 'features/app';

const ProjectSingleNext = ({ linkClick, project: { slug } }) => {
    const [hover, setHover] = useState(false);

    const projects = useContext(AppContext).projects;
    const currentIndex = projects.findIndex(({ slug: itemSlug }) => slug === itemSlug);
    let nextIndex = currentIndex + 1;
    if (nextIndex > projects.length - 1) {
        nextIndex = 0;
    }
    const nextProject = projects[nextIndex];

    return (
        <div className="container-single-next">
            <div className="page-single-next">
                <Link
                    to={`/projects/${nextProject.slug}/`}
                    className="single-next-box"
                    onClick={linkClick('Projects', `/projects/${nextProject.slug}/`)}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div className="single-next-title">
                        <span>Next Project</span> – <br />
                        <span>{nextProject.title}</span>
                        <br />
                        <span>{nextProject.city}</span>
                    </div>
                </Link>
                <img
                    className={cx('single-next-image', { 'single-next-image--hover': hover })}
                    src={nextProject.next_image}
                    srcSet={`${nextProject.next_image} 1x, ${nextProject.next_image_2x} 2x`}
                    alt={`${nextProject.title} / ${nextProject.city}`}
                />
            </div>
        </div>
    );
};
export default ProjectSingleNext;
