import React, { Fragment, useRef, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import { AppContext } from 'features/app';

const modalRoot = document.getElementById('modal-root');

const HomeTourPopup = ({ close, isOpen, code }) => {
    return ReactDOM.createPortal(
        <div className={cx('home-tour-popup', { 'home-tour-popup--open': isOpen })}>
            <iframe
                src={`https://my.matterport.com/show/?m=${code}&brand=0`}
                frameBorder="0"
                allowFullScreen
                title="Virtual Tour"
            ></iframe>
            <div className="home-tour-popup-close" onClick={close}></div>
        </div>,
        modalRoot,
    );
};

const HomeTour = () => {
    const hometourData = useContext(AppContext).hometour;

    const refContainer = useRef(null);
    const refImage = useRef(null);
    const [posX, setPosX] = useState();
    useEffect(() => {
        const handleMouseMove = (e) => {
            const mousePosX = e.clientX;
            const percent = mousePosX / document.body.clientWidth;

            const containerWidth = refContainer.current.getBoundingClientRect().width;
            const imgWidth = refImage.current.getBoundingClientRect().width;
            const diff = containerWidth - imgWidth;
            const translateX = -1 * diff * percent;
            setPosX(translateX);
        };

        document.body.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.body.removeEventListener('mousemove', handleMouseMove);
        };
    });

    const [tourIsOpen, setIsTourOpen] = useState(false);
    const tourOpen = () => setIsTourOpen(true);
    const tourClose = () => setIsTourOpen(false);

    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    if (!hometourData) {
        return null;
    }

    return (
        <Fragment>
            <div className="home-tour-bg"></div>
            <div className={cx('home-tour-content', { 'in-view': inView })} ref={ref}>
                <div className="layout">
                    <div className="home-tour-content-inside">
                        <h3 className="home-tour-text element-title-in-view">
                            <span>Discover our</span>
                            <br />
                            <span>projects through</span>
                            <br />
                            <span>Virtual Tour</span>
                        </h3>
                        <div onClick={tourOpen} className="home-tour-button element-in-view element-in-view-4">
                            <span>Start</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cx('home-tour-image', 'element-in-view', 'element-in-view-4', { 'in-view': inView })}
                ref={refContainer}
            >
                <img
                    src={hometourData.panorama}
                    alt="Virtual Tour"
                    ref={refImage}
                    style={{ transform: `translateX(${-posX}px)` }}
                />
            </div>
            <HomeTourPopup close={tourClose} isOpen={tourIsOpen} code={hometourData.code} />
        </Fragment>
    );
};

export default HomeTour;
