import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Footer } from 'features/footer';

import { Home } from 'pages/home';
import { Projects, ProjectSingle } from 'pages/projects';
import { Profile } from 'pages/profile';
import { Contact } from 'pages/contact';

// helper functions
const MathUtils = {
    // map number x from range [a, b] to [c, d]
    map: (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c,
    // linear interpolation
    lerp: (a, b, n) => (1 - n) * a + n * b,
};

const getPageYScroll = () => window.pageYOffset || document.documentElement.scrollTop;

const SmoothScroll = ({ linkClick }) => {
    const { pathname } = useLocation();
    const [translateY, setTranslateY] = useState(getPageYScroll());

    const refContainer = useRef(null);
    const refScrollable = useRef(null);
    const refShouldAnimate = useRef(false);
    const refRequestAnimationFrame = useRef(null);
    const refUpdateHeight = useRef(null);

    const refPageScrollY = useRef(getPageYScroll());
    const refScrollCurrent = useRef(getPageYScroll());
    const refScrollPrevious = useRef(getPageYScroll());

    useEffect(() => {
        const updateSize = () => {
            if (refContainer.current && refScrollable.current) {
                refContainer.current.style.height = `${refScrollable.current.scrollHeight}px`;

                clearTimeout(refUpdateHeight.current);
                refUpdateHeight.current = setTimeout(() => {
                    refContainer.current.style.height = `${refScrollable.current.scrollHeight}px`;
                }, 50);
            }
        };

        updateSize();
        setTimeout(() => {
            updateSize();
        }, 1000);
        window.addEventListener('resize', updateSize);
        window.addEventListener('scroll', updateSize);
        return () => {
            window.removeEventListener('resize', updateSize);
            window.removeEventListener('scroll', updateSize);
        };
    }, [pathname]);

    useEffect(() => {
        const updateScroll = () => {
            refPageScrollY.current = getPageYScroll();
        };

        updateScroll();
        window.addEventListener('scroll', updateScroll);
        return () => {
            window.removeEventListener('scroll', updateScroll);
        };
    }, []);

    useEffect(() => {
        const updateState = () => {
            const ease = 0.1;
            refScrollCurrent.current = refPageScrollY.current;
            refScrollPrevious.current = MathUtils.lerp(refScrollPrevious.current, refScrollCurrent.current, ease);
            if (Math.abs(refScrollCurrent.current - refScrollPrevious.current) < 0.1) {
                refScrollPrevious.current = refScrollCurrent.current;
            }
            if (refScrollable.current) {
                refScrollable.current.style.transform = `translate3d(0,${-1 * refScrollPrevious.current}px,0)`;
                setTranslateY(refScrollPrevious.current);
            }

            // The 'state' will always be the initial value here
            if (refShouldAnimate.current === true) {
                refRequestAnimationFrame.current = requestAnimationFrame(updateState);
            }
        };

        refShouldAnimate.current = true;
        refRequestAnimationFrame.current = requestAnimationFrame(updateState);

        return () => {
            refShouldAnimate.current = false;
            cancelAnimationFrame(refRequestAnimationFrame.current);
        };
    }, []);

    return (
        <div className="smooth-scroll-container" ref={refContainer}>
            <div className="smooth-scroll-fixed">
                <div className="smooth-scroll-scrollable" ref={refScrollable}>
                    <Switch>
                        <Route exact path="/">
                            <Home linkClick={linkClick} translateY={translateY} />
                        </Route>
                        <Route exact path="/projects/:slug/">
                            <ProjectSingle linkClick={linkClick} translateY={translateY} />
                        </Route>
                        <Route exact path="/projects/">
                            <Projects linkClick={linkClick} translateY={translateY} />
                        </Route>
                        <Route exact path="/profile/">
                            <Profile linkClick={linkClick} translateY={translateY} />
                        </Route>
                        <Route exact path="/contact/">
                            <Contact linkClick={linkClick} translateY={translateY} />
                        </Route>
                    </Switch>
                    <Footer linkClick={linkClick} />
                </div>
            </div>
        </div>
    );
};

export default SmoothScroll;
