import React, { Fragment, useContext } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import { AppContext } from 'features/app';

import SingleIntro from './SingleIntro';
import SingleZoom from './SingleZoom';
import SingleImages from './SingleImages';
import SingleNext from './SingleNext';

const ProjectSingle = ({ linkClick }) => {
    const projects = useContext(AppContext).projects;

    const { slug } = useParams();
    const project = projects.find(({ slug: itemSlug }) => slug === itemSlug);
    if (typeof project === 'undefined') {
        return <Redirect to="/projects/" />;
    }

    return (
        <Fragment>
            <SingleIntro project={project} />
            <SingleZoom project={project} />
            <SingleImages project={project} />
            <SingleNext project={project} linkClick={linkClick} />
        </Fragment>
    );
};
export default ProjectSingle;
