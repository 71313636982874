import React, { Fragment, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';

const emptyError = {
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    message: false,
};

const ContactForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [sending, setSending] = useState(false);
    const [error, setError] = useState({ ...emptyError });
    const [responseType, setResponseType] = useState(null);

    const errorAny = Object.keys(error).reduce((acc, key) => acc || error[key], false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const error = { ...emptyError };

        error.firstName = firstName === '';
        error.lastName = lastName === '';
        const emailRegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/; // eslint-disable-line max-len
        error.email = !emailRegExp.test(email);
        error.phone = phone === '';
        error.message = message === '';

        const errorAny = Object.keys(error).reduce((acc, key) => error[key] || acc, false);
        if (errorAny === false) {
            setSending(true);

            try {
                const response = await axios({
                    method: 'post',
                    url: '/code/form-contact.php', // `${process.env.PUBLIC_URL}/code/form-contact.php`,
                    data: {
                        firstName: firstName.substring(0, 50),
                        lastName: lastName.substring(0, 50),
                        phone: phone.substring(0, 20),
                        email: email.substring(0, 100),
                        message: message.substring(0, 10000),
                    },
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });

                if (response.data && response.data.success === true) {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                    setError({ ...emptyError });
                    setSending(false);
                    setResponseType('success');

                    setTimeout(() => {
                        setResponseType(null);
                    }, 5000);
                } else {
                    setError({
                        firstName: !!response.data.error_firstName,
                        lastName: !!response.data.error_lastName,
                        phone: !!response.data.error_phone,
                        email: !!response.data.error_email,
                        message: !!response.data.error_message,
                    });
                    setSending(false);
                    setResponseType('error');
                }
            } catch (err) {
                console.log(err);
                setError({ ...emptyError });
                setSending(false);
                setResponseType('error');
            }
        } else {
            setError(error);
            setResponseType('error');
        }
    };

    return (
        <div className="container-contact-form">
            <div className="layout layout-padding-hor">
                <div className="page-contact-form">
                    {responseType === 'success' && (
                        <div className="contact-form-info">
                            <h2 className="contact-form-title">Done & Done</h2>
                            <p className="contact-form-text">
                                We have received your message and would like to thank you for writing to us. We will
                                reply by email as soon as possible.
                                <br />
                                Cheers!
                            </p>
                        </div>
                    )}
                    {responseType !== 'success' && (
                        <Fragment>
                            <div className="contact-form-info">
                                <h2 className="contact-form-title">Fill out the form</h2>
                                <p className="contact-form-text">
                                    If using the form to reach us, please expect a response within 24 hours.
                                    <br /> Thank you.
                                </p>
                            </div>
                            <form action="" method="post" className="contact-form" noValidate onSubmit={handleSubmit}>
                                <div className="input-row">
                                    <div className={cx('input-box', { 'with-error': error.firstName })}>
                                        <label htmlFor="contact-first-name">First Name</label>
                                        <input
                                            type="text"
                                            id="contact-first-name"
                                            name="first-name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                        {error.firstName && (
                                            <div className="input-box-error">error. please try again</div>
                                        )}
                                    </div>
                                    <div className={cx('input-box', { 'with-error': error.lastName })}>
                                        <label htmlFor="contact-last-name">Last Name</label>
                                        <input
                                            type="text"
                                            id="contact-last-name"
                                            name="last-name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                        {error.lastName && (
                                            <div className="input-box-error">error. please try again</div>
                                        )}
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className={cx('input-box', { 'with-error': error.email })}>
                                        <label htmlFor="contact-email">Email</label>
                                        <input
                                            type="email"
                                            id="contact-email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {error.email && <div className="input-box-error">error. please try again</div>}
                                    </div>
                                    <div className={cx('input-box', { 'with-error': error.phone })}>
                                        <label htmlFor="contact-phone">Phone</label>
                                        <input
                                            type="text"
                                            id="contact-phone"
                                            name="phone"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                        {error.phone && <div className="input-box-error">error. please try again</div>}
                                    </div>
                                </div>
                                <div className="input-bottom">
                                    <div className={cx('input-box', 'textarea-box', { 'with-error': error.message })}>
                                        <label htmlFor="contact-message">Message</label>
                                        <textarea
                                            id="contact-message"
                                            name="message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                        {error.message && (
                                            <div className="input-box-error">error. please try again</div>
                                        )}
                                    </div>
                                    <div className="button-box">
                                        <button type="submit" disabled={sending || responseType === 'success'}>
                                            <span>Send</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="input-footer">
                                    <div className="input-note">All fields required</div>
                                    {(responseType === 'error' || errorAny) && (
                                        <div className="input-note error">
                                            One or more fields have an error. Please check and try again.
                                        </div>
                                    )}
                                </div>
                            </form>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
