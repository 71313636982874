import { useReducer } from 'react';

const coverInitialState = {
    active: false,
    step: 0,
    url: null,
    text: '',
};

const coverReducer = (state, action) => {
    switch (action.type) {
        case 'start':
            return {
                active: true,
                step: 0,
                url: action.payload.url,
                text: action.payload.text,
            };
        case 'progress':
            if (state.step < 3) {
                return {
                    ...state,
                    step: state.step + 1,
                };
            }
            return {
                ...coverInitialState,
            };
        default:
            throw new Error();
    }
};

const useCover = () => {
    const [coverState, coverDispatch] = useReducer(coverReducer, coverInitialState);
    return [coverState, coverDispatch];
};

export default useCover;
