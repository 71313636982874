import { useState } from 'react';

const useMenu = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const menuOpen = () => setMenuIsOpen(true);
    const menuClose = () => setMenuIsOpen(false);

    return [menuIsOpen, menuOpen, menuClose];
};

export default useMenu;
