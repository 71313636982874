import React from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

const logos = [
    {
        img: require('../assets/press/profile-press-awi.png'),
        img2x: require('../assets/press/profile-press-awi@2x.png'),
        alt: 'AWI - Architectural Woodwork Institute',
    },
    {
        img: require('../assets/press/profile-press-ad.png'),
        img2x: require('../assets/press/profile-press-ad@2x.png'),
        alt: 'Architectural Digest',
    },
    {
        img: require('../assets/press/profile-press-id.png'),
        img2x: require('../assets/press/profile-press-id@2x.png'),
        alt: 'Interior Design',
    },
];

const ProfilePress = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div className={cx('container-profile-press', { 'in-view': inView })} ref={ref}>
            <div className="layout layout-padding-hor">
                <div className="page-profile-press">
                    <h5 className="profile-press-title element-title-in-view">
                        <span>Press –</span>
                    </h5>
                    <p className="profile-press-text element-in-view element-in-view-3">
                        We are proud to have been recognized by a variety of architectural organizations and
                        publications for our work.
                    </p>
                    <div className="profile-press-list">
                        {logos.map(({ img, img2x, alt }, index) => (
                            <div
                                className={cx(
                                    'profile-press-item',
                                    'element-in-view',
                                    `element-in-view-${index * 2 + 5}`,
                                )}
                                key={index}
                            >
                                <img src={img} srcSet={`${img} 1x, ${img2x} 2x`} alt={alt} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProfilePress;
