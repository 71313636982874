import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import useHover from '../hooks/useHover';
import useSlider from '../hooks/useSlider';

import img1 from '../assets/intro/intro-1.jpg';
import img1_2x from '../assets/intro/intro-1@2x.jpg';
import img2 from '../assets/intro/intro-2.jpg';
import img2_2x from '../assets/intro/intro-2@2x.jpg';
import img3 from '../assets/intro/intro-3.jpg';
import img3_2x from '../assets/intro/intro-3@2x.jpg';

const slides = [
    {
        img: img1,
        img2x: img1_2x,
        alt: 'Zale Contracting',
    },
    {
        img: img2,
        img2x: img2_2x,
        alt: 'Zale Contracting',
    },
    {
        img: img3,
        img2x: img3_2x,
        alt: 'Zale Contracting',
    },
];

const HomeIntro = ({ linkClick }) => {
    const [refImage, hover, hoverPos, mouseEnter, mouseLeave, mouseMove] = useHover();
    const hoverStyle = {
        transform: `translate(${hoverPos.x}px, ${hoverPos.y}px)`,
    };

    const [slide, slidePrev] = useSlider(slides.length);
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div className={cx('container-home-intro', { 'in-view': inView })} ref={ref}>
            <div className="layout layout-padding-hor">
                <div className="page-home-intro">
                    <div className="home-intro-inside">
                        <div className="home-intro-content">
                            <h1 className="home-intro-title element-title-in-view">
                                <span>Zale</span> <span>Contracting</span> <span>is</span> <span>a</span>{' '}
                                <span className="gold">high-end</span> <span>interior</span>{' '}
                                <span className="gold">design firm in New York</span> <span>specializing</span>{' '}
                                <span>in</span> <span>general</span> <span>contracting</span>{' '}
                                <span>& architectural</span> <span>woodworking.</span>
                            </h1>
                            <p className="home-intro-scroll element-in-view element-in-view-15">scroll to discover</p>
                        </div>
                        <div
                            ref={refImage}
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                            onMouseMove={mouseMove}
                            className="home-intro-image home-hover-container element-in-view element-in-view-8"
                        >
                            <div className="home-slider">
                                {slides.map(({ img, img2x, alt }, index) => (
                                    <img
                                        className={cx({ active: index === slide, prev: index === slidePrev })}
                                        key={index}
                                        src={img}
                                        srcSet={`${img} 1x, ${img2x} 2x`}
                                        alt={alt}
                                    />
                                ))}
                            </div>
                            <Link
                                to={`/projects/`}
                                onClick={linkClick('Projects', `/projects/`)}
                                className={cx('home-hover', { hovering: hover })}
                                style={hoverStyle}
                            >
                                <span>
                                    View
                                    <br /> Projects
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeIntro;
