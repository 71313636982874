import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import awi from '../assets/logo-awi.svg';

const Footer = ({ linkClick }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const updateScroll = () => {
            const scrollHeight = document.body.scrollHeight;
            const scrollY = window.pageYOffset || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const distanceFromEnd = scrollHeight - scrollY - windowHeight;
            setShow(distanceFromEnd < 100);
        };

        updateScroll();
        window.addEventListener('scroll', updateScroll);
        return () => {
            window.removeEventListener('scroll', updateScroll);
        };
    });

    const year = (new Date()).getFullYear();

    return (
        <footer className="container-footer">
            <div className="layout layout-padding-hor">
                <div className="page-footer">
                    <div className="footer-header">
                        <h4 className="footer-header-title">
                            We don’t build homes.
                            <br /> We build relationships!
                        </h4>
                        <div className="footer-header-button">
                            <Link to="/contact/" onClick={linkClick('Contact', '/contact/')}>
                                Request Free Consultation
                            </Link>
                        </div>
                    </div>
                    <div className={cx('footer-content', { 'footer-content--show': show })}>
                        <address className="footer-content-section footer-content-address">
                            <a href="https://goo.gl/maps/h6r7XqeJ5AA8Zb9U9" target="_blank" rel="noopener noreferrer">
                                <span className="footer-link">1740 Decatur Street</span>
                                <br />
                                <span className="footer-link">Ridgewood, New York 11385</span>
                            </a>
                        </address>
                        <div className="footer-content-section footer-content-hours">
                            Office Hours:
                            <br />
                            M-F 8:00 AM - 4:00 PM
                        </div>
                        <div className="footer-content-section footer-content-contact">
                            <a className="footer-link" href="tel:718 366 1092">
                                (718) 366-1092
                            </a>
                            <br />
                            <a className="footer-link" href="mailto:info@zaleinc.net">
                                info@zaleinc.net
                            </a>
                        </div>
                        <div className="footer-content-section footer-content-social">
                            <a
                                href="https://www.instagram.com/explore/tags/zalecontracting/"
                                className="footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                instagram
                            </a>
                            <br />
                            <a
                                href="https://www.facebook.com/ZaleContractingInc/"
                                className="footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                facebook
                            </a>
                        </div>
                        <p className="footer-content-section footer-content-copyright">
                            <span>© {year} by Zale Contracting, Inc. All Right Reserved.</span>
                            <br />
                            <a
                                href="http://takticstudio.com/"
                                className="footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                by Taktic Studio
                            </a>
                        </p>
                    </div>
                    <img
                        className={cx('footer-logo-awi', { 'footer-logo-awi--show': show })}
                        src={awi}
                        alt="Architectural Woodwork Institute"
                    />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
