import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import useHover from '../hooks/useHover';
import useSlider from '../hooks/useSlider';

import img1 from '../assets/contracting/home-contracting-1.jpg';
import img1_2x from '../assets/contracting/home-contracting-1@2x.jpg';
import img2 from '../assets/contracting/home-contracting-2.jpg';
import img2_2x from '../assets/contracting/home-contracting-2@2x.jpg';
import img3 from '../assets/contracting/home-contracting-3.jpg';
import img3_2x from '../assets/contracting/home-contracting-3@2x.jpg';

const slides = [
    {
        img: img1,
        img2x: img1_2x,
        alt: 'General Contracting',
    },
    {
        img: img2,
        img2x: img2_2x,
        alt: 'General Contracting',
    },
    {
        img: img3,
        img2x: img3_2x,
        alt: 'General Contracting',
    },
];

const HomeContracting = ({ linkClick }) => {
    const [refImage, hover, hoverPos, mouseEnter, mouseLeave, mouseMove] = useHover();
    const hoverStyle = {
        transform: `translate(${hoverPos.x}px, ${hoverPos.y}px)`,
    };

    const [slide, slidePrev] = useSlider(slides.length);
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div className={cx('container-home-contracting', { 'in-view': inView })} ref={ref}>
            <div className="layout layout-padding-hor">
                <div className="page-home-contracting">
                    <div className="home-contracting-content">
                        <h2 className="home-contracting-title element-title-in-view">
                            <span>General</span>
                            <br /> <span>Contracting</span>
                        </h2>
                        <p className="home-contracting-text element-in-view element-in-view-3">
                            Whether it&apos;s a multimillion-dollar technical facility or the renovation of a single
                            office, Zale Conctracting is committed to exceeding our clients&apos; expectations through a
                            proactive and progressive approach to every project.
                        </p>
                        <Link
                            to={`/projects/`}
                            onClick={linkClick('Projects', `/projects/`)}
                            className="home-contracting-link element-in-view element-in-view-4"
                        >
                            View Projects
                        </Link>
                    </div>
                    <div
                        className="home-contracting-image home-hover-container element-in-view element-in-view-4"
                        ref={refImage}
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                        onMouseMove={mouseMove}
                    >
                        <div className="home-slider">
                            {slides.map(({ img, img2x, alt }, index) => (
                                <img
                                    className={cx({ active: index === slide, prev: index === slidePrev })}
                                    key={index}
                                    src={img}
                                    srcSet={`${img} 1x, ${img2x} 2x`}
                                    alt={alt}
                                />
                            ))}
                        </div>
                        <Link
                            to={`/projects/`}
                            onClick={linkClick('Projects', `/projects/`)}
                            className={cx('home-hover', { hovering: hover })}
                            style={hoverStyle}
                        >
                            <span>
                                View
                                <br /> Projects
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeContracting;
