import React from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

const ProfileFounder = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div className={cx('container-profile-founder', { 'in-view': inView })} ref={ref}>
            <div className="container-profile-founder-bg">
                <div className="layout layout-bg"></div>
            </div>
            <div className="layout layout-padding-hor">
                <div className="page-profile-founder">
                    <div className="profile-founder-image element-in-view element-in-view-8">
                        <img src={require('../assets/profile-founder.jpg')} alt="Benny Zale" />
                    </div>
                    <div className="profile-founder-content">
                        <h4 className="profile-founder-title element-title-in-view">
                            <span>Our</span> <span>founder –</span>
                            <br /> <span>Benny</span> <span>Zale,</span> <span>is personally</span>{' '}
                            <span>involved in</span> <span>every</span> <span>project,</span> <span>not only</span>{' '}
                            <span>with his</span> <span>mind</span> <span>but</span> <span>with his</span>{' '}
                            <span className="heart" role="img" aria-label="Heart">
                                🖤
                            </span>{' '}
                            <span>.</span>
                        </h4>
                        <div className="profile-founder-text element-in-view element-in-view-16">
                            He has earned a reputation for delivering outstanding results, while earning the respect and
                            trust of his clients.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProfileFounder;
