import React from 'react';

const ContactHeader = () => {
    return (
        <div className="container-contact-header">
            <div className="layout layout-padding-hor">
                <div className="page-contact-header">
                    <h1 className="contact-header-title">
                        Please contact us and our staff of qualified professionals{' '}
                        <span className="gold">will be happy to assist you.</span>
                    </h1>
                    <div className="contact-header-company">
                        <address className="contact-header-address">
                            <a href="https://goo.gl/maps/h6r7XqeJ5AA8Zb9U9" target="_blank" rel="noopener noreferrer">
                                <span className="contact-header-link">1740 Decatur Street</span>
                                <br />
                                <span className="contact-header-link">Ridgewood, New York 11385</span>
                            </a>
                        </address>
                        <div className="contact-header-hours">
                            Office Hours:
                            <br />
                            M-F 8:00 AM - 4:00 PM
                        </div>
                        <div className="contact-header-phone-email">
                            <a className="contact-header-link" href="tel:718 366 1092">
                                (718) 366-1092
                            </a>
                            <br />
                            <a className="contact-header-link" href="mailto:info@zaleinc.net">
                                info@zaleinc.net
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactHeader;
